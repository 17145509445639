/////////////////////////////
// MENU STICKY
var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("header").style.bottom = "0";
    document.getElementById("list_category").style.height = "auto";
  } else {
    document.getElementById("header").style.bottom = "-70px";
    document.getElementById("list_category").style.height = "0";
  }
  prevScrollpos = currentScrollPos;
}


/////////////////////////////
// MENU MOBILE

var btnMobile = document.querySelector("#btnMenu");
var navMobile = document.querySelector("#list_category");

if (btnMobile){

  //Ouvrir more text
  btnMobile.addEventListener('click', function() {
    console.log('click menu');
    btnMobile.classList.toggle("rotateCross");
    navMobile.classList.toggle("open");
  });

}

/////////////////////////////
// INFINITE SCROLL PROJET ET CATÉGORIES
var homepage = document.querySelector(".home");
var categoryPage = document.querySelector(".category");

if ( homepage || categoryPage) {

    //-------------------------------------//
    // INIT INFINITE SCROLL
    var listeArticle = document.querySelector('.infiniteScrollContainer');

    var infScroll = new InfiniteScroll( listeArticle, {
      path: '.nextPageLink a',
      append: '.blocArticle',
      // outlayer: msnry,
      status: '.page-load-status',
      history: false,
      hideNav: '.nextPrev'
    });

} //FIN IF HOME ET ARCHIVE


/////////////////////////////
// MASONRY (page agence)
var grille = document.querySelector('.grid');
if (grille) {
  var msnry = new Masonry( grille, {
    // options
    itemSelector: '.grid-item',
    gutter : 30
  });
}


/////////////////////////////
// MORE -TEXTE (page agence)
var articleMores = document.querySelectorAll(".articleAgenceLien");

articleMores.forEach( function(articleMore) {

  articleMore.addEventListener('click', function(){
        
    // var aFermer = document.querySelectorAll(".articleAgenceLien.open");
    // aFermer.forEach( function(fermer) {
    //   fermer.classList.remove('open');
    // });

    articleMore.classList.toggle("open");
    

    var btnMoreTxt = articleMore.querySelector(".buttonLirePlus");
    if (btnMoreTxt.innerHTML === "en lire plus") {
      btnMoreTxt.innerHTML = "fermer";
    } else {
      btnMoreTxt.innerHTML = "en lire plus";
    }

    // Masonry : trigger layout
    msnry.layout();

  });

/////////////////////////////
// INFINITE SCROLL AGENCE (+masonry)
var pageAgence = document.querySelector(".post-type-archive-agence");

if ( pageAgence) {

    //-------------------------------------//
    // INIT INFINITE SCROLL
    var listeArticle = document.querySelector('.infiniteScrollContainer');

    var infScroll = new InfiniteScroll( listeArticle, {
      path: '.nextPageLink a',
      append: '.blocArticle',
      outlayer: msnry,
      status: '.page-load-status',
      history: false,
      hideNav: '.nextPrev'
    });

} //FIN IF HOME ET ARCHIVE

});